<template>
  <div class="home">
    <banner></banner>
    <h1>公司简介</h1>
    <el-row>
      <el-col>
        <div class="grid-content bg-purple">
          <p>
            山东锦智数字科技有限公司成立于2022年，公司拥有独立的研发和技术服务中心，凭借着软件研发，咨询规划，技术服务等业务，通过整合人工智能、云技术、大数据、物联网、移动互联等新一代技术，面向国企，为客户提供全方位信息化智慧服务，协助企业实现智慧管理
          </p>
        </div>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <div class="grid-content bg-purple">
          <p>
            公司拥有自己的项目研发团队，团队成员来自于不同领域，包括人工智能、电子信息工程、计算机信息管理、信息工程等专业，团队拥有丰富的产品需求分析及设计开发的经验和技能，对于大数据、人工智能和系统开发等方面都有相关工作经验，团队成员具有高度的责任感和团队合作精神，他们能够相互配合完成研发工作，同时也能吸收来自不同领域的先进技术，不断提高团队的整体水平。
          </p>
        </div>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <div class="grid-content bg-purple">
          <p>
            自公司成立以来，参与的重大工程与项目的信息化发展规划及建设工作，得到了客户高度的认可。白尺竿头更进一步，山东锦智数字科技有限公司将不断开拓创新，走在科技前沿，为客户提供更好的服务，帮助客户实现智慧管理的目标。
          </p>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import banner from '../components/banner.vue'
export default {
  name: "Home",
  components:{
    banner
  }
};
</script>
<style lang="less" scoped>
.home{
  width: 100%;
  height: 100%;
  h1{
    margin: 10px 0;
  }
}

.el-row {
    // margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .el-col {
    border-radius: 4px;
  }
  .bg-purple-dark {
    background: #E5F3FE;
  }
  .bg-purple {
    background: #E5F3FE;
  }
  .bg-purple-light {
    background: #E5F3FE;
  }
  .grid-content {
    border-radius: 4px;
    min-height: 36px;
  }
  .row-bg {
    background-color: #f9fafc;
  }
  .grid-content>p{
    text-indent: 28px;
    padding:10px;
    font-size: 16px;
    margin: 0;
  }
</style>
